.Tales-h1 {
    animation-direction: alternate;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-name: title;
    background-color: transparent;
    font-size: large;
    text-align: center;
}

.Tales-div {
    padding: 0.5rem;
    font-size: inherit;
    line-height: 1.5;
    background-color: transparent;
}

.Tales-a {
    font-size: inherit;
    border: 1px dashed orangered;
    padding: 0.25rem;
}

.Tales-p {
    background-color: inherit;
    font-size: large;
}

.Tales-legend {
    background-color: transparent;
}

.Tales-fieldset {
    background:
        linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
        linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
        linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
        linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
        linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
        linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
    background-color: #131313;
    background-size: 20px 20px;
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    max-width: 75%;
    min-width: 75;
    padding: 0.5rem;
    width: 75%;
}

.Number-span {
    background-color: transparent;
    font-size: inherit;
    position: relative;
}

.Why-button {
    animation-direction: alternate;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-name: title;
    background: linear-gradient(to bottom, antiquewhite 0%, cadetblue 100%);
    border-radius: 25px;
    border: 1px solid #e2efd3;
    height: 1.75rem;
    width: 3.5rem;
}

.Why-p {
    background-color: inherit;
    font-size: large;
}

.Why-span {
    background-color: transparent;
    color: gold;
    font-size: 14px;
    position: relative;
}