.IntroductionHishIsle-div {
    background-image: url('https://mainbuilds.com/media/chapter/high-isle.png');
    /* background-position: 90rem; */
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    animation:
        randomBackgroundPositionX 15s infinite alternate,
        randomBackgroundPositionY 21s infinite alternate;
}

.IntroductionNecrom-div {
    background-image: url('https://mainbuilds.com/media/chapter/necrom.png');
    /* background-position: 5rem; */
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    animation:
        randomBackgroundPositionX 15s infinite alternate,
        randomBackgroundPositionY 21s infinite alternate;
}

.IntroductionBlackwood-div {
    background-image: url('https://mainbuilds.com/media/chapter/blackwood.jpg');
    /* background-position: 155rem; */
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    animation:
        randomBackgroundPositionX 15s infinite alternate,
        randomBackgroundPositionY 21s infinite alternate;
}

.IntroductionGoldRoad-div {
    background-image: url('https://mainbuilds.com/media/chapter/gold-road.jpg');
    /* background-position: 155rem; */
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    animation:
        randomBackgroundPositionX 15s infinite alternate,
        randomBackgroundPositionY 21s infinite alternate;
}

@keyframes randomBackgroundPositionX {
    0% {
        background-position-x: 0%;
    }

    25% {
        background-position-x: 25%;
    }

    50% {
        background-position-x: 50%;
    }

    75% {
        background-position-x: 75%;
    }

    100% {
        background-position-x: 100%;
    }
}

@keyframes randomBackgroundPositionY {
    0% {
        background-position-y: 0%;
    }

    25% {
        background-position-y: 25%;
    }

    50% {
        background-position-y: 50%;
    }

    75% {
        background-position-y: 75%;
    }

    100% {
        background-position-y: 100%;
    }
}

.SkillName-h1 {
    font-size: inherit;
    background-color: transparent;
}

.Description-h1 {
    font-size: inherit;
    text-decoration: underline;
    background-color: transparent
}

.Morph-h1 {
    font-size: inherit;
    color: greenyellow;
    text-decoration: underline;
    background-color: transparent;
}

.Tooltip-p {
    font-size: inherit;
    background-color: transparent;
}

.Morph-p {
    font-size: inherit;
    color: greenyellow;
    background-color: transparent;
}

.Title-div,
.Top-div {
    justify-content: center;
    text-align: center;
    background-color: transparent;
}

.Creator-h1,
.Patch-h1 {
    background-color: transparent;
    font-size: inherit;
}

.Creator-div {
    text-align: center;
    display: inline-block;
    max-width: calc(100vw / 2);
    padding-right: 2rem;
    background-color: inherit;
}

.Patch-div {
    text-align: center;
    display: inline-block;
    max-width: calc(100vw / 2);
    padding-left: 2rem;
    background-color: inherit;
}

.Left-div {
    display: inline-block;
    max-width: calc(100vw / 2);
    vertical-align: top;
    background-color: inherit;
    vertical-align: top;
}

.Right-div {
    display: inline-block;
    max-width: calc(100vw / 2);
    vertical-align: top;
    background-color: inherit;
}

.Patch-img {
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    margin-left: 0.25rem;
}

.FB-div,
.BB-div {
    overflow-x: auto;
}

.FB-fieldset,
.BB-fieldset {
    width: fit-content;
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    background-image: url('https://mainbuilds.com/media/background/black-slate.png');
    margin: auto;
}

.FB-legend,
.BB-legend {
    background-color: inherit;
    background-color: transparent;
}

.Skills-div {
    display: flex;
    justify-content: center;
    background-color: inherit;
    background-color: transparent;
}

.Space-div {
    background-color: transparent;
    width: 0.75rem;
    padding: 0rem;
    margin: 0rem;
}

.Slotted-div {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.Slotted-table {
    background-color: goldenrod;
    border-radius: 0.25rem;
    border: 0.15rem solid goldenrod;
    display: block;
    margin: 0 auto;
    max-width: fit-content;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
}

.Slotted-th {
    text-align: center;
    background-color: goldenrod;
    font-size: inherit;
}

.X-h1 {
    font-size: inherit;
    font-weight: bolder;
    text-align: center;
    background-color: inherit;
}

.X-span {
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%;
    border: 0.1em solid blue;
    color: white;
    background-color: black;
    font-weight: 600;
    font-stretch: condensed;
}

.Y-h1 {
    font-size: inherit;
    font-weight: bolder;
    text-align: center;
    background-color: rgb(80, 80, 80);
    margin: 0;
    padding: 0;
}

.Y-span {
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%;
    border: 0.1em solid yellow;
    color: white;
    background-color: black;
    font-weight: 600;
    font-stretch: condensed;
}

.B-h1 {
    font-size: inherit;
    font-weight: bolder;
    color: red;
    text-align: center;
    background-color: inherit;
}

.B-span {
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%;
    border: 0.1em solid red;
    color: white;
    background-color: black;
}

.LB-h1 {
    font-size: inherit;
    font-weight: bolder;
    color: black;
    text-align: center;
    background-color: rgb(80, 80, 80);
}

.RB-h1 {
    font-size: inherit;
    font-weight: bolder;
    color: white;
    text-align: center;
    background-color: gray;
}

.LB-span,
.RB-span {
    display: inline-block;
    width: 1.25em;
    height: 1em;
    line-height: 1em;
    text-align: center;
    border: 0.1em solid black;
    color: black;
    background-color: white;
    font-weight: 600;
    font-stretch: condensed;
    text-shadow: none;
}

.Space-span {
    display: inline-block;
    width: 1.25em;
    height: 1em;
    line-height: 1em;
    text-align: center;
    border: 0.1em solid black;
    color: black;
    background-color: white;
    font-weight: 600;
    font-stretch: condensed;
    margin-left: 0.25em;
    text-shadow: none;
}

.Gear-table {
    background-color: goldenrod;
    border-radius: 0.25rem;
    border: 0.15rem solid goldenrod;
    display: block;
    margin: 0 auto;
    max-width: fit-content;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
}

a {
    background-color: inherit;
    color: inherit;
}

a:hover {
    transition: all 0.2s ease-in;
    color: orangered;
}

.BW-td {
    background-color: gray;
    text-align: center;
    font-size: inherit;
}

.BG-td {
    background-color: rgb(80, 80, 80);
    text-align: center;
    font-size: inherit;
}

.White-td,
.White-tr {
    background-color: gray;
    font-size: inherit;
}

.Transparent-td {
    background-color: transparent;
    font-size: inherit;
}

.Gray-td,
.Gray-tr {
    background-color: rgb(80, 80, 80);
    font-size: inherit;
}

.Magicka-td,
.Health-td,
.Stamina-td,
.Vampire-td,
.None-td,
.Werewolf-td {
    background-image: url('https://mainbuilds.com/media/background/black-slate.png');
    color: white;
    width: 33%;
}

.Heavy-td,
.None-th,
.Health-th {
    background-color: #FF6666;
}

.Medium-td,
.Werewolf-th,
.Stamina-th {
    background-color: #339933;
}

.Light-td,
.Vampire-th,
.Magicka-th {
    background-color: #5C5CF6;
}

.Blank-td {
    text-align: center;
}

.Consumable-div,
.Mundus-div,
.Potion-div,
.Race-div {
    display: flex;
    justify-content: center;
    background-color: transparent;
}

.Warfare-h1,
.Fitness-h1,
.Craft-h1 {
    font-weight: bolder;
    display: table;
    background: transparent;
}

.Title-h1 {
    font-size: 25px;
}

.Bar-h1,
.Consumable-h1,
.Mundus-h1,
.Potion-h1,
.Race-h1,
.Affliction-h1,
.Attributes-h1,
.Warfare-h1,
.Fitness-h1,
.Craft-h1,
.GIF-h1 {
    background-color: inherit;
    background: transparent;
    font-size: large;
    font-weight: bolder;
    text-align: center;
}

.Title-h1 {
    animation-direction: alternate;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-name: title;
}

@keyframes title {
    from {
        color: var(--randomColorOne);
    }

    to {
        color: inherit;
    }
}

.Slotted-th {
    background-color: inherit;
    background: transparent;
    font-size: medium;
    font-weight: bolder;
    text-align: center;
}

.Consumable-img,
.Mundus-img,
.Potion-img,
.Race-img {
    background-color: transparent;
    height: 15rem;
}

.Consumable-fieldset,
.Mundus-fieldset,
.Potion-fieldset,
.Race-fieldset {
    display: inline-block;
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    width: 15rem;
    background-image: url('https://mainbuilds.com/media/background/black-slate.png');
}

.Name-div {
    position: absolute;
    background: transparent;
}

.Name-span {
    border-bottom: 1px solid whitesmoke;
    display: inline-block;
    vertical-align: top;
    background-color: inherit;
}

.Name-h1 {
    padding-top: 6.5rem;
    font-weight: bolder;
    background: transparent;
    font-size: inherit;
}

.Affliction-div,
.Attribute-div {
    width: 100%;
}

.Affliction-table,
.Attribute-table {
    background-color: goldenrod;
    border-radius: 0.25rem;
    border: 0.15rem solid goldenrod;
    min-width: 15rem;
    text-align: center;
}

.North-div {
    overflow: hidden;
    text-align: center;
    vertical-align: top;
}

.South-div {
    overflow: hidden;
    text-align: center;
    vertical-align: top;
}

.NW-div,
.NE-div,
.SW-div,
.SE-div {
    display: inline-block;
    vertical-align: top;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.Description-div {
    width: 14.5rem;
    background-color: inherit;
}

.Description-span {
    background-color: inherit;
    font-size: small;
    line-height: 0% !important;
}

.Health-span {
    color: #FF6666;
    background-color: inherit;
    font-size: inherit;
}

.HR-span {
    color: lightcoral;
    background-color: inherit;
    font-size: inherit;
}

.Gold-span {
    color: gold;
    background-color: inherit;
    font-size: inherit;
}

.Stamina-span {
    color: #339933;
    background-color: inherit;
    font-size: inherit;
}

.SR-span {
    color: lightgreen;
    background-color: inherit;
    font-size: inherit;
}

.Magicka-span {
    color: #5C5CF6;
    background-color: inherit;
    font-size: inherit;
}

.MR-span {
    color: lightblue;
    background-color: inherit;
    font-size: inherit;
}

.Champion-h1 {
    text-align: center;
    background-color: inherit;
}

.Champion-div {
    width: 100%;
    overflow: hidden;
    text-align: center;
}

.Warfare-div {
    display: inline-block;
    height: 12rem;
    width: 12rem;
    text-align: left;
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    background-image: url('https://mainbuilds.com/media/champion/warfare.png');
    background-position: center;
}

.Fitness-div {
    display: inline-block;
    height: 12rem;
    width: 12rem;
    text-align: left;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    background-image: url('https://mainbuilds.com/media/champion/fitness.png');
    background-position: center;
}

.Craft-div {
    display: inline-block;
    height: 12rem;
    width: 12rem;
    text-align: left;
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    background-image: url('https://mainbuilds.com/media/champion/craft.png');
    background-position: center;
}

.Champion-ul {
    position: absolute;
    padding-left: 2rem;
    width: 9rem;
    background: transparent;
    display: table;
}

.Champion-span {
    position: relative;
    top: -5px;
    background-color: inherit;
    border-bottom: 1px solid whitesmoke;
}

.Champion-li {
    list-style-image: url('https://mainbuilds.com/champion/champion-point-star.png');
    font-weight: bolder;
    background: transparent;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}

.GIF-div {
    text-align: center;
}

.GIF-img {
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    max-width: calc(100vw - 0.5rem);
}