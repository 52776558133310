.Footer-footer {
  background: linear-gradient(to left, gray, #454545);
  box-sizing: border-box;
  min-height: 3rem;
  overflow: hidden;
  width: 100%;
}

.Footer-div {
  min-height: inherit;
  text-align: center;
}

.D-div,
.Years-div,
.Pipe-div {
  display: inline-block;
  padding: 1rem 0.5em;
}

.Years-h1,
.Pipe-h1 {
  font-size: 17.5px;
  font-weight: inherit;
}

.Disclaimer-span {
  font-size: 17.5px;
}

.Disclaimer-span:hover {
  transition: all 0.2s ease-in;
  color: orangered;
}