.Header-header {
  background: linear-gradient(to left, gray, #454545);
  min-height: 3rem;
  width: 100%;
}

.Header-div {
  display: inline-flex;
  min-height: inherit;
}

.Header-span {
  font-size: 17.5px;
}

.Header-span:hover {
  transition: all 0.2s ease-in;
  color: orangered;
}

.Menu-ul {
  display: flex;
  padding-left: auto;
}

.Menu-ul>.Menu-li {
  margin: 0 1rem;
  overflow: hidden;
}

.Menu-label {
  align-items: center;
  cursor: pointer;
  display: none;
  flex-direction: column;
  height: 1.4rem;
  justify-content: center;
  margin-left: 1rem;
  width: 1.875rem;
}

.Home-Link {
  margin-left: 1rem;
}

#Menu-input {
  display: none;
}

.Button-div,
.Button-div::before,
.Button-div::after {
  background-color: white;
  border-radius: 2px;
  display: block;
  height: 4px;
  position: absolute;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  width: 30px;
}

.Button-div::before {
  content: '';
  margin-top: -8px;
}

.Button-div::after {
  content: '';
  margin-top: 8px;
}

#Menu-input:checked+.Menu-label .Button-div::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#Menu-input:checked+.Menu-label .Button-div {
  background: rgba(255, 255, 255, 0);
}

#Menu-input:checked+.Menu-label .Button-div::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

.Menu-label {
  display: flex;
}

.Menu-ul {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  left: 0;
  margin-top: 3rem;
  position: absolute;
  top: 0;
  width: fit-content;
}

#Menu-input~.Menu-ul .Menu-li {
  border: 0;
  height: 0;
  padding: 0;
  z-index: 1;
}

#Menu-input:checked~.Menu-ul .Menu-li {
  border-radius: 25px;
  border: 0.15rem solid var(--randomColorOne);
  box-sizing: border-box;
  height: fit-content;
  margin-bottom: -0.15rem;
  padding: 0.25rem;
}

.Menu-ul>.Menu-li {
  background-color: #222;
  color: white;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0.5em 0;
  width: 100%;
}

.Menu-ul>.Menu-li:not(:last-child) {
  border-bottom: 1px solid #444;
}